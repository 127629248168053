var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Form text & Datalist helper"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeHelper) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Username",
      "label-for": "h-username"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "h-username",
      "placeholder": "Username"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "d-email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "d-email",
      "type": "email",
      "placeholder": "Email"
    }
  }), _c('b-form-text', [_vm._v("Enter a Valid Email")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Password",
      "label-for": "d-password"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "d-password",
      "type": "password",
      "placeholder": "Password"
    }
  }), _c('b-form-text', [_vm._v("Your password must be 8-20 characters long.")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Occupation",
      "label-for": "h-occupation"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "h-occupation",
      "list": "input-list",
      "placeholder": "Occupation"
    }
  }), _c('b-form-datalist', {
    attrs: {
      "id": "input-list",
      "options": _vm.options
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-9",
      "name": "checkbox-9",
      "value": "Remember_me"
    }
  }, [_vm._v(" Remember me ")])], 1)], 1), _c('b-col', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }