var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Vertical Form"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVertical) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "First Name",
      "label-for": "v-first-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-first-name",
      "placeholder": "First Name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "v-email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-email",
      "type": "email",
      "placeholder": "Email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mobile",
      "label-for": "v-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-mobile",
      "type": "number",
      "placeholder": "Mobile"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Password",
      "label-for": "v-password"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-password",
      "type": "password",
      "placeholder": "Password"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-3",
      "name": "checkbox-3",
      "value": "Remember_me"
    }
  }, [_vm._v(" Remember me ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }