var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Form Feedback helpers"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFeedbackHelper) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Username",
      "label-for": "fh-usename"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "fh-usename",
      "placeholder": "Username"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "fh-email"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "fh-email",
      "type": "email",
      "placeholder": "Email"
    }
  })], 1), _c('b-form-text', {
    staticClass: "text-danger"
  }, [_vm._v(" Enter a Valid Email ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Password",
      "label-for": "fh-password"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge",
    class: _vm.validation ? 'is-valid' : 'is-invalid'
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LockIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "fh-password",
      "type": "password",
      "state": _vm.validation,
      "placeholder": "Password"
    },
    model: {
      value: _vm.userId,
      callback: function callback($$v) {
        _vm.userId = $$v;
      },
      expression: "userId"
    }
  })], 1), _c('b-form-invalid-feedback', {
    attrs: {
      "state": _vm.validation
    }
  }, [_vm._v(" Your password must be 8-20 characters long. ")]), _c('b-form-valid-feedback', {
    attrs: {
      "state": _vm.validation
    }
  }, [_vm._v(" Looks Good. ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Occupation"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge",
    class: _vm.validationOccupation ? 'is-valid' : 'is-invalid'
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LockIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "list": "input-list",
      "placeholder": "Occupation",
      "state": _vm.validationOccupation
    },
    model: {
      value: _vm.occupationID,
      callback: function callback($$v) {
        _vm.occupationID = $$v;
      },
      expression: "occupationID"
    }
  })], 1), _c('b-form-datalist', {
    attrs: {
      "id": "input-list",
      "options": _vm.options
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-8",
      "name": "checkbox-8",
      "value": "Remember_me"
    }
  }, [_vm._v(" Remember me ")])], 1)], 1), _c('b-col', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }